.Drinkmode {
    
    display: flex;
    flex-direction: column;
    align-items: center ;
    height: 600px;
}

.drinkcard {
    display: flex;
    background-color: rgba(18, 17, 17, 0.526);
    border-radius: 12px;
    width: 800px;
    height: 200px;
    padding: 25px;
    color: white;
}

.icard {

    background-color: rgba(26, 20, 20, 0.897);
    height: 350px;
    width: 330px;
    margin-right: 20px;
    margin-top: -90px;
    border-radius: 12px;
    padding: 20px 20px 0px 20px;
}

.img{

    height: 300px;
    width:300px;

}

.p1{

    line-height: 25.5px;
    font-size: 20px;
    margin-top: 50px;

}

.hh1{

    line-height: 25.5px;
    color: white;
    display: flex;
    margin-top: 10px;
    font-size: 60px;
}

@media only screen and (max-width: 768px) {

    .Drinkmode {

        display: flex;
        flex-direction: column;
        align-items: center;
        height: 300px;
        /* background-color: white; */
        margin: 10px 10px 10px 10px;
       
     


    } 
    .drinkcard {
        display: flex;
        background-color: rgba(18, 17, 17, 0.526);
        border-radius: 12px;
        width: 250px;
      
        padding: 10px 10px 10px 10px;
       
        color: white;
        position: relative;
        margin-top: 50px;
    }
    .icard {
        display: flex;
        background-color: rgba(26, 20, 20, 0.897);
        height: 90px;
        width: 70px;
        margin-left: -50px;
        margin-top: -50px;
        border-radius: 12px;
        position: absolute;
    }

    .img{

        height: 80px;
        width: 80px;
    
    }
    .hh1{

        display: flex;
        justify-content: center;
        line-height: 45.5px;
        color: white;
        font-size: 40px;
        padding-top: 10px;
        padding-left: 70px;
        margin-top: 10px;

    }
    .p1{

        line-height: 25.5px; 
        font-size: 16px;
        padding-left: 45px;
        padding-right: 20px;
        font-weight: bold;
        margin-top: 10px;
    
    }
}