.Funmode {

    display: flex;
    flex-direction: column;
    align-items: center;
    height: 600px;
    overflow-x: hidden;
    margin-top: 80px;
}

.funcard {
    display: flex;
    background-color: rgba(18, 17, 17, 0.526);
    border-radius: 12px;
    width: 800px;
    height: 200px;
    margin-top: 50px;
    padding: 45px;
    color: white;
}

.icard {

    background-color: rgba(26, 20, 20, 0.897);
    height: 350px;
    width: 330px;
    margin-left: 70px;
    /* margin-right: 20px; */
    margin-top: -80px;
    border-radius: 12px;
    padding: 20px 20px 0px 20px;
}
.img{
    height: 300px;
    width: 300px;
}
.p1{
    line-height: 25.5px; 
    font-size: 20px;
    margin-top: 50px; 
}
.hh1{
    line-height: 25.5px; 
    color: white;
    display: flex;
   
    font-size: 60px;
    margin-top: 10px;
}
@media only screen and (max-width: 768px) {

    .Funmode {

        display: flex;
        flex-direction: column;
        align-items: center;
        height: 420px;
        overflow-x: hidden;
        /* background-color: white; */
        margin: 10px 10px 10px 10px;
        margin-top: 50px;

    }
    
    .funcard {
        display: flex;
        background-color: rgba(18, 17, 17, 0.526);
        border-radius: 12px;
        height: 155px;
        width: 180px;
        color: white;
    } 
    .p1{
        line-height: 25.5px; 
        font-size: 16px; 
        font-weight: bold;
        margin-top: 20px;
    }
    .hh1{
        color: white;
        display: flex;
        font-size: 40px;
        margin-top: 30px;
        line-height: 45.5px; 
        width: 200px;
       
    }
    
    .icard {
    
        background-color: rgba(26, 20, 20, 0.897);
        height: 90px;
        width: 150px;
        margin-left: -60px;
        margin-top: -90px;
        border-radius: 12px;
        /* padding: 20px 20px 0px 20px; */
    }
    .img{
        height: 80px;
        width: 80px;
    }

}