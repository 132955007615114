.footer {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
    backdrop-filter: blur(25px);
    -webkit-backdrop-filter: blur(25px);
    backdrop-filter: blur(25px);
    background-color: rgb(0, 0, 0); 
    background-color: rgba(0, 0, 0, 0.4); 
    height: 300px;
    /* margin-top: 100px; */
  }
  #contact {
    display: flex;
    align-items: center;
  }
  #contact > div > a {
    margin: 10px 10px;
    font-size: 25px;
  }
  #contact img {
    height: 80px;
  }
  #button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 20px;
    color: white;
  }
  #button h1 {
    font-size: 45px;
  }
  #button a img {
    height: 50px;
    margin-right: 30px;
    border-radius: 10px;
  }
  #socialmedia {
    color: white;
  }
  
  .copy {
    color: white;
    /* text-shadow: 0 1px rgba(255, 255, 255, 0.1); */
    text-align: center;
  }
  .link a{
    text-decoration: none;
    color: white;
  }