.button {
  margin-top: 5px;
  margin-right: 25px;
  height: 60px;
  width: 225px;
  font-size: 20px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: whitesmoke;
  background-color: black;
  border: none;
  border-radius: 45px;
  transition: all 0.5s;
}
.button:hover {
  background-color: #fff;
  color: black;
}

@media only screen and (max-width: 768px) {
    .button {
     
        margin-right: 0px;

      }
}
