
html {
  scroll-behavior: smooth;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media only screen and (max-width: 600px) {

  body{
  background-repeat: no-repeat;

  }
  

}


.navbar {
  width: 100vw;
  font-size: 18px;
  padding-bottom: 10px;
  margin-right: 20px;
}


.main-nav {
  list-style-type: none;
 
}

.nav-links,
.logo {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.7);
}

.main-nav li {
  text-align: center;
  margin: 15px auto;
}

.logo {
  display: inline-block;
  font-size: 22px;
  margin-top: 10px;
  margin-left: 20px;
}

.navbar-toggle {
  position: absolute;
  top: 30px;
  right: 20px;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.8);
  font-size: 24px;
}

#chkToggle {
  display: none;
}

#chkToggle:checked + ul.main-nav {
  display: block;
}

@media screen and (min-width: 768px) {
  .navbar {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0;
    height: 100px;
    align-items: center;
    transition: all .3s;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100;
    overflow: hidden;
  }
  .topNavScrollView {
    backdrop-filter: blur(25px);
    -webkit-backdrop-filter: blur(25px);
    backdrop-filter: blur(25px);
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/opacity/see-through */

}

  #chkToggle:checked + ul.main-nav {
    display: flex;
  }

  .main-nav {
    display: flex;
    margin-right: 30px;
    flex-direction: row;
    justify-content: flex-end;
  }

  .main-nav li {
    margin: 0;
  }

  .nav-links {
    margin-left: 40px;
  }

  .logo {
    margin-top: 0;
  }

  .navbar-toggle {
    display: none;
  }

  .logo:hover,
  .nav-links:hover {
    color: #FEC129;
  }

  .topNavLi :hover {
    color: #FEC129;
    /* background-color: rgb(var(--txts,var(--color_14)));; */
}



.topNavLi {
    flex: 2 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    overflow: auto
        /* style: auto */
}

.topNavLi li {
    list-style: none;
    padding: 0px 10px;
    font-size: 18px;
}
}





body{
 
/* backdrop-filter: brightness(0.5);  */
background: linear-gradient(90deg, hsla(339, 100%, 55%, 1) 0%, hsla(197, 100%, 64%, 1) 100%);
width: 100vw;
margin: 0;
font-family: 'Crimson Pro', serif;
font-family: 'Montserrat', sans-serif;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
overflow-x: hidden;
background-repeat: no-repeat;

}

* {
  margin: 0px;
  padding: 0px;
}

.profile__img img {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  margin: auto;
}

.Home_main__1sV2S {
  margin-top: 160px;
  color: white;
  width: "100vw";
  overflow-x: "hidden";
 
}
.Home_title__1jouD {
  font-size: 90px;
  margin-top: 265px;
  word-spacing: 1px;
  font-weight: bold;
}
.Home_slogan__1kYx4 {
  margin-top: 100px;
  font-size: 35px;
  margin-top: 5px;
  margin-bottom: 105px;
  word-spacing: 1px;
  width: 100vw;
  font-weight: bold;
}
.Home_small__2jZNg {
  font-size: 32px;
}
.Home_download__E_rm1{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  object-fit: contain;
  margin-bottom: 90px;
}

.Home_titleMain__7xK-y {
  font-size: 60px;
}


@media only screen and (max-width: 768px) {

  .Home_titleMain__7xK-y {
    font-size: 45px;
  }
  .Home_title__1jouD {
    font-size: 35px;
    margin-top: 235px;
    word-spacing: 1px;
    font-weight: bold;
    margin-bottom: 0px;
  }
  .Home_slogan__1kYx4 {
    font-size: 25px;
    margin-bottom: 100px;
    word-spacing: 1px;
    margin-top: 35px;
  }
  .Home_small__2jZNg {
    font-size: 15px;
  }
  .Home_download__E_rm1 a{
    padding: 10px;
    margin-bottom: 40px;
  }
}

@media (max-width: 900px) {
  .Home_two__3RbWP {
    font-size: 20px;
    margin-top: 125px;
    margin-bottom: 75px;
    word-spacing: 1px;
  }
  .Home_small__2jZNg {
    font-size: 12px;
  }
}

.Button_button__W74uT {
  margin-top: 5px;
  margin-right: 25px;
  height: 60px;
  width: 225px;
  font-size: 20px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: whitesmoke;
  background-color: black;
  border: none;
  border-radius: 45px;
  transition: all 0.5s;
}
.Button_button__W74uT:hover {
  background-color: #fff;
  color: black;
}

@media only screen and (max-width: 768px) {
    .Button_button__W74uT {
     
        margin-right: 0px;

      }
}

.Footer_footer__2s0Ii {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
    backdrop-filter: blur(25px);
    -webkit-backdrop-filter: blur(25px);
    backdrop-filter: blur(25px);
    background-color: rgb(0, 0, 0); 
    background-color: rgba(0, 0, 0, 0.4); 
    height: 300px;
    /* margin-top: 100px; */
  }
  #Footer_contact__10nih {
    display: flex;
    align-items: center;
  }
  #Footer_contact__10nih > div > a {
    margin: 10px 10px;
    font-size: 25px;
  }
  #Footer_contact__10nih img {
    height: 80px;
  }
  #Footer_button__1_Bvh {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 20px;
    color: white;
  }
  #Footer_button__1_Bvh h1 {
    font-size: 45px;
  }
  #Footer_button__1_Bvh a img {
    height: 50px;
    margin-right: 30px;
    border-radius: 10px;
  }
  #Footer_socialmedia__279rY {
    color: white;
  }
  
  .Footer_copy__13vFz {
    color: white;
    /* text-shadow: 0 1px rgba(255, 255, 255, 0.1); */
    text-align: center;
  }
  .Footer_link__2xm7w a{
    text-decoration: none;
    color: white;
  }
.Contactus_login__HKgI- {
  top: 50%;
  left: 50%;
  padding: 40px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: rgba(18, 17, 17, 0.526);
  box-sizing: border-box;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  position: relative;
  margin-top: 250px;
  width: 550px;
  height: 390px;
}
.Contactus_title__2nZs- {
  font-size: 60px;
}

.Contactus_login__HKgI- h2 {
  margin: 0 0 30px;
  padding: 0;
  color: #fff;
  text-align: center;
}

.Contactus_login__HKgI- .Contactus_user__3pHTK {
  position: relative;
}
.Contactus_user__3pHTK {
  margin-top: 15px;
}
.Contactus_login__HKgI- .Contactus_user__3pHTK input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
}
.Contactus_login__HKgI- .Contactus_user__3pHTK label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  pointer-events: none;
  transition: 0.5s;
}

.Contactus_login__HKgI- .Contactus_user__3pHTK input:focus ~ label,
.Contactus_login__HKgI- .Contactus_user__3pHTK input:valid ~ label {
  top: -20px;
  left: 0;
  color: white;
  font-size: 14px;
}

.Contactus_login__HKgI- form a {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: #03e9f4;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.5s;
  margin-top: 40px;
  letter-spacing: 4px;
}

.Contactus_login__HKgI- a:hover {
  background: #03e9f4;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #03e9f4, 0 0 25px #03e9f4, 0 0 50px #03e9f4,
    0 0 100px #03e9f4;
}

.Contactus_login__HKgI- a span {
  position: absolute;
  display: block;
}

.Contactus_login__HKgI- a span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #03e9f4);
  -webkit-animation: Contactus_btn-anim1__3Df9l 1s linear infinite;
          animation: Contactus_btn-anim1__3Df9l 1s linear infinite;
}

@-webkit-keyframes Contactus_btn-anim1__3Df9l {
  0% {
    left: -100%;
  }
  50%,
  100% {
    left: 100%;
  }
}

@keyframes Contactus_btn-anim1__3Df9l {
  0% {
    left: -100%;
  }
  50%,
  100% {
    left: 100%;
  }
}

.Contactus_login__HKgI- a span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #03e9f4);
  -webkit-animation: Contactus_btn-anim2__RDUlU 1s linear infinite;
          animation: Contactus_btn-anim2__RDUlU 1s linear infinite;
  -webkit-animation-delay: 0.25s;
          animation-delay: 0.25s;
}

@-webkit-keyframes Contactus_btn-anim2__RDUlU {
  0% {
    top: -100%;
  }
  50%,
  100% {
    top: 100%;
  }
}

@keyframes Contactus_btn-anim2__RDUlU {
  0% {
    top: -100%;
  }
  50%,
  100% {
    top: 100%;
  }
}

.Contactus_login__HKgI- a span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #03e9f4);
  -webkit-animation: Contactus_btn-anim3__SpOxg 1s linear infinite;
          animation: Contactus_btn-anim3__SpOxg 1s linear infinite;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}

@-webkit-keyframes Contactus_btn-anim3__SpOxg {
  0% {
    right: -100%;
  }
  50%,
  100% {
    right: 100%;
  }
}

@keyframes Contactus_btn-anim3__SpOxg {
  0% {
    right: -100%;
  }
  50%,
  100% {
    right: 100%;
  }
}

.Contactus_login__HKgI- a span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #03e9f4);
  -webkit-animation: Contactus_btn-anim4__348yq 1s linear infinite;
          animation: Contactus_btn-anim4__348yq 1s linear infinite;
  -webkit-animation-delay: 0.75s;
          animation-delay: 0.75s;
}

@-webkit-keyframes Contactus_btn-anim4__348yq {
  0% {
    bottom: -100%;
  }
  50%,
  100% {
    bottom: 100%;
  }
}

@keyframes Contactus_btn-anim4__348yq {
  0% {
    bottom: -100%;
  }
  50%,
  100% {
    bottom: 100%;
  }
}


@media only screen and (max-width: 768px) {

  .Contactus_login__HKgI- {
    /* position: absolute; */
   
    width: auto;
    height: auto;
    padding: 14px;
  }
  .Contactus_title__2nZs- {
    font-size: 45px;
  }
  
}

@media (max-width: 900px) {
  

  .Contactus_login__HKgI- {
    /* position: absolute; */
   
    width: auto;
    height: auto;
    padding: 14px;
  }


}


.About_super__2TCxN{
display: flex;
flex-direction: column;
padding: 0px 200px;
}

.About_title__UQMmq {
  font-size: 60px;
}

center h1 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.About_img__tIhhp{
  width: 35vh;
}
.About_maindiv__3uhY_ {
  height: auto;

  display: flex;
  flex-direction: row-reverse;
  
  align-items: center;
  justify-content: center;

}
.About_profile__inner__1qfkk {
  flex: 6 1;
  font-size: 24px;
  text-align: justify;
}

.About_profile__img__282JP {
  flex: 4 1;
}

@media only screen and (max-width: 768px) {
  .About_img__tIhhp{
    width: 25vh;
  }
  .About_super__2TCxN{
    display: flex;
    flex-direction: column;
    padding: 0px 30px;
    
    }
    .About_profile__inner__1qfkk {
      flex: 1 1;
      font-size: 18px;
    }
    
    .About_profile__img__282JP {
      flex: 1 1;
    }
  .About_maindiv__3uhY_ {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }

  .About_title__UQMmq {
    font-size: 45px;
   
  }
  center h1 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
}


.Funmode_Funmode__2ZSJt {

    display: flex;
    flex-direction: column;
    align-items: center;
    height: 600px;
    overflow-x: hidden;
    margin-top: 80px;
}

.Funmode_funcard__1AFbt {
    display: flex;
    background-color: rgba(18, 17, 17, 0.526);
    border-radius: 12px;
    width: 800px;
    height: 200px;
    margin-top: 50px;
    padding: 45px;
    color: white;
}

.Funmode_icard__3rCi2 {

    background-color: rgba(26, 20, 20, 0.897);
    height: 350px;
    width: 330px;
    margin-left: 70px;
    /* margin-right: 20px; */
    margin-top: -80px;
    border-radius: 12px;
    padding: 20px 20px 0px 20px;
}
.Funmode_img__3Slfe{
    height: 300px;
    width: 300px;
}
.Funmode_p1__UxYVc{
    line-height: 25.5px; 
    font-size: 20px;
    margin-top: 50px; 
}
.Funmode_hh1__1CwNn{
    line-height: 25.5px; 
    color: white;
    display: flex;
   
    font-size: 60px;
    margin-top: 10px;
}
@media only screen and (max-width: 768px) {

    .Funmode_Funmode__2ZSJt {

        display: flex;
        flex-direction: column;
        align-items: center;
        height: 420px;
        overflow-x: hidden;
        /* background-color: white; */
        margin: 10px 10px 10px 10px;
        margin-top: 50px;

    }
    
    .Funmode_funcard__1AFbt {
        display: flex;
        background-color: rgba(18, 17, 17, 0.526);
        border-radius: 12px;
        height: 155px;
        width: 180px;
        color: white;
    } 
    .Funmode_p1__UxYVc{
        line-height: 25.5px; 
        font-size: 16px; 
        font-weight: bold;
        margin-top: 20px;
    }
    .Funmode_hh1__1CwNn{
        color: white;
        display: flex;
        font-size: 40px;
        margin-top: 30px;
        line-height: 45.5px; 
        width: 200px;
       
    }
    
    .Funmode_icard__3rCi2 {
    
        background-color: rgba(26, 20, 20, 0.897);
        height: 90px;
        width: 150px;
        margin-left: -60px;
        margin-top: -90px;
        border-radius: 12px;
        /* padding: 20px 20px 0px 20px; */
    }
    .Funmode_img__3Slfe{
        height: 80px;
        width: 80px;
    }

}
.Drinkmode_Drinkmode__2DqpQ {

    display: flex;
    flex-direction: column;
    align-items: center ;
    height: 600px;
    overflow: visible;
}
.Drinkmode_drinkcard__2tvM8 {
    display: flex;
    background-color: rgba(18, 17, 17, 0.526);
    border-radius: 12px;
    width: 800px;
    height: 200px;
    padding: 25px;
    color: white;
}
.Drinkmode_icard__3A360 {

    background-color: rgba(26, 20, 20, 0.897);
    height: 350px;
    width: 330px;
    margin-right: 20px;
    margin-top: -90px;
    border-radius: 12px;
    padding: 20px 20px 0px 20px;
}
.Drinkmode_img__1nK_J{

    height: 300px;
    width: 300px;

}
.Drinkmode_p1__98-EE{

    line-height: 35.5px; 
    font-size: 20px;
    margin-top: 50px;

}
.Drinkmode_hh1__3_USu{

    line-height: 25.5px;
    color: white;
    display: flex;
    margin-top: 20px;
    font-size: 60px;

}
@media only screen and (max-width: 768px) {

    .Drinkmode_Drinkmode__2DqpQ {

        display: flex;
        flex-direction: column;
        align-items: center;
        /* background-color: white; */
        margin: 0px 10px 10px 10px;

        height: 250px;
        margin-top: 50px;
    } 
    .Drinkmode_drinkcard__2tvM8 {
        display: flex;
        background-color: rgba(18, 17, 17, 0.526);
        border-radius: 12px;
        width: 250px;
        padding: 10px 10px 10px 10px;
        margin-top: 10px;
        color: white;
        position: relative;
    }
    .Drinkmode_icard__3A360 {
        display: flex;
        background-color: rgba(26, 20, 20, 0.897);
        height: 90px;
        width: 80px;
        margin-left: -40px;
        margin-top: -80px;
        border-radius: 12px;
        border-radius: 12px;
        position: absolute;
    }
    .Drinkmode_img__1nK_J{

        height: 80px;
        width: 80px;
    
    }
    .Drinkmode_hh1__3_USu{

        display: flex;
        justify-content: center;
        line-height: 45.5px;
        color: white;
        font-size: 40px;
        padding-top: 10px;
        padding-left: 70px;
        margin-top: 20px;

    }
    .Drinkmode_p1__98-EE{

        line-height: 25.5px; 
        font-size: 16px;
        font-weight: bold;
        padding-left: 45px;
        padding-right: 20px;
        margin-top: 10px;
    
    }
}
.Challengemode_Funmode__1qdv- {
    
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 600px;
}

.Challengemode_funcard__1FuBY {
    display: flex;
    background-color: rgba(18, 17, 17, 0.526);
    border-radius: 12px;
    width: 800px;
    height: 200px;
    padding: 35px;
    color: white;
}

.Challengemode_icard__2d9Lm {

    background-color: rgba(26, 20, 20, 0.897);
    height: 350px;
    width: 330px;
    margin-left: 70px;
    /* margin-right: 20px; */
    margin-top: -80px;
    border-radius: 12px;
    padding: 20px 20px 0px 20px;
}
.Challengemode_img__3EIdf{

    height: 300px;
    width: 300px;

}
.Challengemode_p1__3TO4P{

    line-height: 25.5px; 
    font-size: 20px ;
    margin-top: 50px;

}
.Challengemode_hh1__3RxZ3{

    color: white;
    display: flex;
    margin-top: 10px;
    font-size: 60px;
    line-height: 25.5px; 
    font-size: 45px; 
}

@media only screen and (max-width: 768px) {

    .Challengemode_Funmode__1qdv- {

        display: flex;
        flex-direction: column;
        align-items: center;
          height: 420px;
        overflow-x: hidden;
        /* background-color: white; */
        margin: 10px 10px 10px 10px;
        margin-bottom: 30px;
        overflow: visible;
    }
    
    .Challengemode_funcard__1FuBY {
        display: flex;
        background-color: rgba(18, 17, 17, 0.526);
        border-radius: 12px;
        width: 180px;
        height: 155px;
        margin-top: 130px;
        /* padding: 10px 0px 60px 40px; */
        color: white;
        overflow: visible;
    } 
    .Challengemode_p1__3TO4P{
        line-height: 25.5px; 
        font-size: 16px; 
        font-weight: bold;
        margin-top: 15px;
    }
    .Challengemode_hh1__3RxZ3{
        color: white;
        display: flex;
        font-size: 40px;
        margin-top: 15px;
        line-height: 45.5px; 
    }
    
    .Challengemode_icard__2d9Lm {
    
        background-color: rgba(26, 20, 20, 0.897);
        height: 90px;
        width: 90px;
        margin-left: -55px;
        margin-top: -90px;
        border-radius: 12px;
        /* padding: 20px 20px 0px 20px; */
        opacity:0
    }
    .Challengemode_img__3EIdf{
        height: 80px;
        width: 80px;
    }

}
.Hotmode_Drinkmode__8Z5sV {
    
    display: flex;
    flex-direction: column;
    align-items: center ;
    height: 600px;
}

.Hotmode_drinkcard__2h964 {
    display: flex;
    background-color: rgba(18, 17, 17, 0.526);
    border-radius: 12px;
    width: 800px;
    height: 200px;
    padding: 25px;
    color: white;
}

.Hotmode_icard__35gnt {

    background-color: rgba(26, 20, 20, 0.897);
    height: 350px;
    width: 330px;
    margin-right: 20px;
    margin-top: -90px;
    border-radius: 12px;
    padding: 20px 20px 0px 20px;
}

.Hotmode_img__IpQtM{

    height: 300px;
    width:300px;

}

.Hotmode_p1__3WFbt{

    line-height: 25.5px;
    font-size: 20px;
    margin-top: 50px;

}

.Hotmode_hh1__1uniy{

    line-height: 25.5px;
    color: white;
    display: flex;
    margin-top: 10px;
    font-size: 60px;
}

@media only screen and (max-width: 768px) {

    .Hotmode_Drinkmode__8Z5sV {

        display: flex;
        flex-direction: column;
        align-items: center;
        height: 300px;
        /* background-color: white; */
        margin: 10px 10px 10px 10px;
       
     


    } 
    .Hotmode_drinkcard__2h964 {
        display: flex;
        background-color: rgba(18, 17, 17, 0.526);
        border-radius: 12px;
        width: 250px;
      
        padding: 10px 10px 10px 10px;
       
        color: white;
        position: relative;
        margin-top: 50px;
    }
    .Hotmode_icard__35gnt {
        display: flex;
        background-color: rgba(26, 20, 20, 0.897);
        height: 90px;
        width: 70px;
        margin-left: -50px;
        margin-top: -50px;
        border-radius: 12px;
        position: absolute;
    }

    .Hotmode_img__IpQtM{

        height: 80px;
        width: 80px;
    
    }
    .Hotmode_hh1__1uniy{

        display: flex;
        justify-content: center;
        line-height: 45.5px;
        color: white;
        font-size: 40px;
        padding-top: 10px;
        padding-left: 70px;
        margin-top: 10px;

    }
    .Hotmode_p1__3WFbt{

        line-height: 25.5px; 
        font-size: 16px;
        padding-left: 45px;
        padding-right: 20px;
        font-weight: bold;
        margin-top: 10px;
    
    }
}
.Spicymode_Funmode__1XqTP {
    
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 550px;
    overflow: visible;
}

.Spicymode_funcard__1SAap {
    display: flex;
    background-color: rgba(18, 17, 17, 0.526);
    border-radius: 12px;
    width: 800px;
    height: 200px;
    padding: 55px;
    color: white;
    overflow: visible;
}

.Spicymode_icard__3I1vQ {

    background-color: rgba(26, 20, 20, 0.897);
    height: 350px;
    width: 330px;
    margin-left: 70px;
    /* margin-right: 20px; */
    margin-top: -80px;
    border-radius: 12px;
    padding: 20px 20px 0px 20px;
}
.Spicymode_img__17Kcd{

    height: 300px;
    width: 300px;

}
.Spicymode_p1__3WVEb{

    line-height: 25.5px; 
    font-size: 20px ;
    margin-top: 50px;

}
.Spicymode_hh1__3lk3P{

    color: white;
    display: flex;
    margin-top: 00px;
    font-size: 60px;
    line-height: 25.5px; 
    font-size: 45px; 
}

@media only screen and (max-width: 768px) {
    .Spicymode_Funmode__1XqTP {

        display: flex;
        flex-direction: column;
        align-items: center;
        height: 420px;
        overflow-x: hidden;
        /* background-color: white; */
        margin: 10px 10px 10px 10px;
        margin-bottom: 30px;
        overflow: visible;
        margin-top: 50px;
    }
    
    .Spicymode_funcard__1SAap {
        display: flex;
        background-color: rgba(18, 17, 17, 0.526);
        border-radius: 12px;
        width: 180px;
        height: 155px;
        margin-top: 70px;
        /* padding: 10px 0px 60px 40px; */
        color: white;
        overflow: visible;
    } 
    .Spicymode_p1__3WVEb{
        line-height: 25.5px; 
        font-size: 16px; 
        font-weight: bold;
        margin-top: 15px;
    }
    .Spicymode_hh1__3lk3P{
        color: white;
        display: flex;
        font-size: 40px;
        margin-top: 5px;
        line-height: 45.5px; 
        width: 200px;
    }
    
    .Spicymode_icard__3I1vQ {
    
        background-color: rgba(26, 20, 20, 0.897);
        height: 90px;
        width: 90px;
        margin-left: -55px;
        margin-top: -90px;
        border-radius: 12px;
        /* padding: 20px 20px 0px 20px; */
        opacity:0
    }
    .Spicymode_img__17Kcd{
        height: 80px;
        width: 80px;
    }

}
.Rules_rules__3F70J {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: justify;
  margin-top: 100px;

  /* padding-top: 40px; */
}
.Rules_rules__3F70J h2 {
  color: white;
  display: flex;
  justify-content: center;
  font-size: 60px;

}
.Rules_rcard__2j1Gm {
  background-color: rgba(18, 17, 17, 0.526);
  /* background-color: rgba(42, 109, 72, 0.608); */
  border-radius: 12px;
  width: 1000px;
  padding: 35px;
  color: white;
  line-height: 15px;
  line-height: 22px;
}
.Rules_rcard__2j1Gm p {
    font-size: 18px;
    line-height: 25.5px;
}
.Rules_title__2Mo9N {
  font-size: 60px;
  
}

@media only screen and (max-width: 768px) {
  .Rules_rules__3F70J {
    padding: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: justify;
  }
  .Rules_rules__3F70J h2 {
    color: white;
    display: flex;
    justify-content: center;
    font-size: 40px;
  }
  .Rules_rcard__2j1Gm {
    height: auto;
    width: auto;
  }
  .Rules_rcard__2j1Gm p {
    font-size: 2px;
    line-height: 18px;
  }
  .Rules_title__2Mo9N {
    font-size: 45px;
  }
}

@media (max-width: 900px) {
  .Rules_rules__3F70J {
    padding: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: justify;
    margin-top: 10px;
  }
  .Rules_rules__3F70J h2 {
    color: white;
    display: flex;
    justify-content: center;
    font-size: 20px;
  }
  .Rules_rcard__2j1Gm {
    height: auto;
    width: auto;
  }
  .Rules_rcard__2j1Gm p {
    font-size: 14px;
    line-height: 20px;
  }
}

.Features_main__mWyWO {
  display: flex;
  justify-content: space-evenly;
  margin-top: 65px;
  align-items: center;
  width: auto;
  flex-wrap: wrap;
  /* width: 10000px;  */
}
.Features_card__1hmtz {
  margin: 14px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 280px;
  height: 400px;
  background-color: rgba(18, 17, 17, 0.526);
  color: white;
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  border-radius: 15px;
}
.Features_card__1hmtz img {
  width: 280px;
  height: 250px;
  margin-top: 25px;
  border-radius: 14px;
  align-items: center;
}
.Features_card__1hmtz:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.Features_container__1Cuhg {
  padding: 2px 16px;
  align-items: center;
  justify-content: center;
  /* height: 65px; */
}
.Features_container__1Cuhg {
  padding: auto;
  display: flex;
  flex-wrap: nowrap;
  text-align: center;
  align-items: center;
  font-size: 18px;
}
.Features_container__1Cuhg > div {
  background-color: #f1f1f1;
  /* width: 100%; */
  /* margin: 10px; */
  /* text-align: center; */
  line-height: 75px;
  font-size: 30px;
  height: 300px;
  color: black;
}
.Features_title__2rc0J {
  font-size: 60px;
}

@media only screen and (max-width: 768px) { 
  .Features_title__2rc0J {
    font-size: 45px;
  }
}
.Logic_rules__1VWmN {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  /* text-align: justify; */
}
.Logic_rules__1VWmN h2 {
  color: white;
  display: flex;
  justify-content: center;
  font-size: 60px;
}
.Logic_rcard__350Cp {
  background-color: rgba(18, 17, 17, 0.526);
  /* background-color: rgba(42, 109, 72, 0.608); */
  border-radius: 12px;
  width: 1000px;
  padding: 35px;
  color: white;
  line-height: 22px;
}
.Logic_rcard__350Cp p {
    font-size: 18px;
    line-height: 25.5px;
}

.Logic_title__1jm3U {
  font-size: 60px;
}

@media only screen and (max-width: 768px) {
  .Logic_title__1jm3U {
    font-size: 45px;
  }
  .Logic_rules__1VWmN {
    padding: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: justify;
  }
  .Logic_rules__1VWmN h2 {
    color: white;
    display: flex;
    justify-content: center;
    font-size: 40px;
  }
  .Logic_rcard__350Cp {
    height: auto;
    width: auto;
  }
  .Logic_rcard__350Cp p {
    font-size: 2px;
    line-height: 18px;
  }
}

@media (max-width: 900px) {
  .Logic_rules__1VWmN {
    padding: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: justify;
    margin-top: 10px;
  }
  .Logic_rules__1VWmN h2 {
    color: white;
    display: flex;
    justify-content: center;
    font-size: 20px;
  }
  .Logic_rcard__350Cp {
    height: auto;
    width: auto;
  }
  .Logic_rcard__350Cp p {
    font-size: 14px;
    line-height: 20px;
  }
}

.Navebar_topNav__3Zmqr {
    /* background-color: #4234be; */
    flex: 1 1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    /* margin-top: 10px; */
    /* padding-left: 20px; */
    transition: all .3s;
    position: fixed;
    top: 0px;
    z-index: 100;
    overflow: hidden;
    width: 100vw;
   padding-top:15px;
    /* height: 100px; */
}

.Navebar_topNavScrollView__3o6zU {
    backdrop-filter: blur(25px);
    -webkit-backdrop-filter: blur(25px);
    backdrop-filter: blur(25px);
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/opacity/see-through */

}

.Navebar_topNavLi__2Ylnv :hover {
    color: #FEC129;
    /* background-color: rgb(var(--txts,var(--color_14)));; */
}
button{

    border: none;
    background: transparent;
    outline: none;
    font-size: 17px;
    color: rgba(255, 255, 255, 0.7);
    font-weight: 510;
    font-family: questrial, sans-serif;
}
button:hover{

    color: #FEC129;

}

.Navebar_topNavRight__2VDdn {
    justify-content: flex-end;
    display: flex;
    list-style: none;
    flex: 1 1;
    align-items: flex-end;
    flex: 1 1;
    font-size: 25px;
    padding: 5px;
    font-weight: 600;
    padding-right: 50px;
    /* font-family: questrial, sans-serif; */
}

.Navebar_topNavLi__2Ylnv {
    flex: 2 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    overflow: auto
        /* style: auto */
}

.Navebar_topNavLi__2Ylnv li {
    list-style: none;
    padding: 0px 10px;
    font-size: 18px;
}




/* 
.topNavRight button {
    font-size: 16px;
    font-weight: 200;
    letter-spacing: 1px;
    padding: 13px 20px 13px;
    outline: 0;
    border: 1px solid black;
    cursor: pointer;
    position: relative;
    background-color: rgba(0, 0, 0, 0);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    touch-action: manipulation;
}
.topNavRight button ::after{
   content: "";
   background-color: #ffe54c;
   width: 100%;
   z-index: -1;
   position: absolute;
   height: 100%;
   top: 7px;
   left: 7px;
   transition: 0.2s;
}
.topNavRight button :hover:after {
  top: 0px;
  left: 0px;
 } */


.Overview_rules__1nMAz {
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: justify;
  z-index: 101;
}
.Overview_rules__1nMAz h2 {
  color: white;
  display: flex;
  justify-content: center;
  font-size: 60px;
}
.Overview_rcard__hVk8O {
  background-color: rgba(18, 17, 17, 0.526);
  border-radius: 12px;
  width: 850px;
  padding: 35px;
  color: white;
  line-height: 22px;
  height: 375px;
}
.Overview_rcard__hVk8O p {
    font-size: 18px;
    line-height: 25.5px;
  padding: 5px;
}
.Overview_span__3g5Cw{

  color: white;;
  
}


@media only screen and (max-width: 768px) {
  .Overview_rules__1nMAz {
    padding: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: justify;
   
  }

  .Overview_rules__1nMAz h2 {
    color: white;
    display: flex;
    justify-content: center;
    font-size: 40px;
  }
  .Overview_rcard__hVk8O {
    height: auto;
    width: auto;
  }
  .Overview_rcard__hVk8O p {
    font-size: 2px;
    line-height: 18px;
  }
}

@media (max-width: 900px) {
  .Overview_rules__1nMAz {
    padding: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: justify;
  }
  .Overview_rules__1nMAz h2 {
    color: white;
    display: flex;
    justify-content: center;
    font-size: 20px;
  }
  .Overview_rcard__hVk8O {
    height: auto;
    width: auto;
  }
  .Overview_rcard__hVk8O p {
    font-size: 14px;
    line-height: 20px;
  }
}

