body{
 
/* backdrop-filter: brightness(0.5);  */
background: linear-gradient(90deg, hsla(339, 100%, 55%, 1) 0%, hsla(197, 100%, 64%, 1) 100%);
width: 100vw;
margin: 0;
font-family: 'Crimson Pro', serif;
font-family: 'Montserrat', sans-serif;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
overflow-x: hidden;
background-repeat: no-repeat;

}

* {
  margin: 0px;
  padding: 0px;
}

.profile__img img {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  margin: auto;
}
