.main {
  margin-top: 160px;
  color: white;
  width: "100vw";
  overflow-x: "hidden";
 
}
.title {
  font-size: 90px;
  margin-top: 265px;
  word-spacing: 1px;
  font-weight: bold;
}
.slogan {
  margin-top: 100px;
  font-size: 35px;
  margin-top: 5px;
  margin-bottom: 105px;
  word-spacing: 1px;
  width: 100vw;
  font-weight: bold;
}
.small {
  font-size: 32px;
}
.download{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  object-fit: contain;
  margin-bottom: 90px;
}

.titleMain {
  font-size: 60px;
}


@media only screen and (max-width: 768px) {

  .titleMain {
    font-size: 45px;
  }
  .title {
    font-size: 35px;
    margin-top: 235px;
    word-spacing: 1px;
    font-weight: bold;
    margin-bottom: 0px;
  }
  .slogan {
    font-size: 25px;
    margin-bottom: 100px;
    word-spacing: 1px;
    margin-top: 35px;
  }
  .small {
    font-size: 15px;
  }
  .download a{
    padding: 10px;
    margin-bottom: 40px;
  }
}

@media (max-width: 900px) {
  .two {
    font-size: 20px;
    margin-top: 125px;
    margin-bottom: 75px;
    word-spacing: 1px;
  }
  .small {
    font-size: 12px;
  }
}
