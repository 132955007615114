.super{
display: flex;
flex-direction: column;
padding: 0px 200px;
}

.title {
  font-size: 60px;
}

center h1 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.img{
  width: 35vh;
}
.maindiv {
  height: auto;

  display: flex;
  flex-direction: row-reverse;
  
  align-items: center;
  justify-content: center;

}
.profile__inner {
  flex: 6;
  font-size: 24px;
  text-align: justify;
}

.profile__img {
  flex: 4;
}

@media only screen and (max-width: 768px) {
  .img{
    width: 25vh;
  }
  .super{
    display: flex;
    flex-direction: column;
    padding: 0px 30px;
    
    }
    .profile__inner {
      flex: 1;
      font-size: 18px;
    }
    
    .profile__img {
      flex: 1;
    }
  .maindiv {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }

  .title {
    font-size: 45px;
   
  }
  center h1 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
}

