.topNav {
    /* background-color: #4234be; */
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    /* margin-top: 10px; */
    /* padding-left: 20px; */
    transition: all .3s;
    position: fixed;
    top: 0px;
    z-index: 100;
    overflow: hidden;
    width: 100vw;
   padding-top:15px;
    /* height: 100px; */
}

.topNavScrollView {
    backdrop-filter: blur(25px);
    -webkit-backdrop-filter: blur(25px);
    backdrop-filter: blur(25px);
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/opacity/see-through */

}

.topNavLi :hover {
    color: #FEC129;
    /* background-color: rgb(var(--txts,var(--color_14)));; */
}
button{

    border: none;
    background: transparent;
    outline: none;
    font-size: 17px;
    color: rgba(255, 255, 255, 0.7);
    font-weight: 510;
    font-family: questrial, sans-serif;
}
button:hover{

    color: #FEC129;

}

.topNavRight {
    justify-content: flex-end;
    display: flex;
    list-style: none;
    flex: 1;
    align-items: flex-end;
    flex: 1;
    font-size: 25px;
    padding: 5px;
    font-weight: 600;
    padding-right: 50px;
    /* font-family: questrial, sans-serif; */
}

.topNavLi {
    flex: 2;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    overflow: auto
        /* style: auto */
}

.topNavLi li {
    list-style: none;
    padding: 0px 10px;
    font-size: 18px;
}




/* 
.topNavRight button {
    font-size: 16px;
    font-weight: 200;
    letter-spacing: 1px;
    padding: 13px 20px 13px;
    outline: 0;
    border: 1px solid black;
    cursor: pointer;
    position: relative;
    background-color: rgba(0, 0, 0, 0);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    touch-action: manipulation;
}
.topNavRight button ::after{
   content: "";
   background-color: #ffe54c;
   width: 100%;
   z-index: -1;
   position: absolute;
   height: 100%;
   top: 7px;
   left: 7px;
   transition: 0.2s;
}
.topNavRight button :hover:after {
  top: 0px;
  left: 0px;
 } */

