
html {
  scroll-behavior: smooth;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media only screen and (max-width: 600px) {

  body{
  background-repeat: no-repeat;

  }
  

}


.navbar {
  width: 100vw;
  font-size: 18px;
  padding-bottom: 10px;
  margin-right: 20px;
}


.main-nav {
  list-style-type: none;
 
}

.nav-links,
.logo {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.7);
}

.main-nav li {
  text-align: center;
  margin: 15px auto;
}

.logo {
  display: inline-block;
  font-size: 22px;
  margin-top: 10px;
  margin-left: 20px;
}

.navbar-toggle {
  position: absolute;
  top: 30px;
  right: 20px;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.8);
  font-size: 24px;
}

#chkToggle {
  display: none;
}

#chkToggle:checked + ul.main-nav {
  display: block;
}

@media screen and (min-width: 768px) {
  .navbar {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0;
    height: 100px;
    align-items: center;
    transition: all .3s;
    position: sticky;
    top: 0;
    z-index: 100;
    overflow: hidden;
  }
  .topNavScrollView {
    backdrop-filter: blur(25px);
    -webkit-backdrop-filter: blur(25px);
    backdrop-filter: blur(25px);
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/opacity/see-through */

}

  #chkToggle:checked + ul.main-nav {
    display: flex;
  }

  .main-nav {
    display: flex;
    margin-right: 30px;
    flex-direction: row;
    justify-content: flex-end;
  }

  .main-nav li {
    margin: 0;
  }

  .nav-links {
    margin-left: 40px;
  }

  .logo {
    margin-top: 0;
  }

  .navbar-toggle {
    display: none;
  }

  .logo:hover,
  .nav-links:hover {
    color: #FEC129;
  }

  .topNavLi :hover {
    color: #FEC129;
    /* background-color: rgb(var(--txts,var(--color_14)));; */
}



.topNavLi {
    flex: 2;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    overflow: auto
        /* style: auto */
}

.topNavLi li {
    list-style: none;
    padding: 0px 10px;
    font-size: 18px;
}
}




