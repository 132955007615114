.rules {
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: justify;
  z-index: 101;
}
.rules h2 {
  color: white;
  display: flex;
  justify-content: center;
  font-size: 60px;
}
.rcard {
  background-color: rgba(18, 17, 17, 0.526);
  border-radius: 12px;
  width: 850px;
  padding: 35px;
  color: white;
  line-height: 22px;
  height: 375px;
}
.rcard p {
    font-size: 18px;
    line-height: 25.5px;
  padding: 5px;
}
.span{

  color: white;;
  
}


@media only screen and (max-width: 768px) {
  .rules {
    padding: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: justify;
   
  }

  .rules h2 {
    color: white;
    display: flex;
    justify-content: center;
    font-size: 40px;
  }
  .rcard {
    height: auto;
    width: auto;
  }
  .rcard p {
    font-size: 2px;
    line-height: 18px;
  }
}

@media (max-width: 900px) {
  .rules {
    padding: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: justify;
  }
  .rules h2 {
    color: white;
    display: flex;
    justify-content: center;
    font-size: 20px;
  }
  .rcard {
    height: auto;
    width: auto;
  }
  .rcard p {
    font-size: 14px;
    line-height: 20px;
  }
}
