.main {
  display: flex;
  justify-content: space-evenly;
  margin-top: 65px;
  align-items: center;
  width: auto;
  flex-wrap: wrap;
  /* width: 10000px;  */
}
.card {
  margin: 14px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 280px;
  height: 400px;
  background-color: rgba(18, 17, 17, 0.526);
  color: white;
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  border-radius: 15px;
}
.card img {
  width: 280px;
  height: 250px;
  margin-top: 25px;
  border-radius: 14px;
  align-items: center;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.container {
  padding: 2px 16px;
  align-items: center;
  justify-content: center;
  /* height: 65px; */
}
.container {
  padding: auto;
  display: flex;
  flex-wrap: nowrap;
  text-align: center;
  align-items: center;
  font-size: 18px;
}
.container > div {
  background-color: #f1f1f1;
  /* width: 100%; */
  /* margin: 10px; */
  /* text-align: center; */
  line-height: 75px;
  font-size: 30px;
  height: 300px;
  color: black;
}
.title {
  font-size: 60px;
}

@media only screen and (max-width: 768px) { 
  .title {
    font-size: 45px;
  }
}